import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import SocialList from '../components/Social-List';

const SocialContainer = styled.div`
  margin: 4rem 0;
  text-align: center;
  .title {
    margin-bottom: 4rem;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  ul {
    li {
      list-style: none;
      display: inline-block;
      padding: 0 1rem;
    }
  }
`;

const Social = () => (
  <SocialContainer className="social">
    <div className="page-width">
      <StaticImage
        className="title"
        src="../images/it-means-tasty-orange.png"
        alt="It Means Tasty!"
        height={60}
      />
      <h3>Follow us to stay up to date</h3>
      <SocialList />
    </div>
  </SocialContainer>
);

export default Social;
