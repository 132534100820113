import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import MenuItem from './Menu-Item';

const MenuContainer = styled.div`
  margin: 2rem auto;
  .menu-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000;
    font-size: 5rem;
    font-weight: bold;
    h2 {
      border-bottom: 5px solid var(--orange);
      display: inline-block;
      margin: 0 0.25rem;
      font-size: 4.2rem;
    }
  }
  .list-container {
    margin: 4rem 0;
  }
  .list-title {
    text-align: center;
    h3 {
      text-transform: uppercase;
      color: var(--orange);
      font-size: 2.5rem;
      font-weight: bold;
      border-bottom: 5px solid var(--orange);
      display: inline-block;
    }
  }
  .menu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .one-half {
      flex: 0 0 50%;
    }
    .one-third {
      flex: 0 0 33.33%;
    }
  }
  @media only screen and (max-width: 1024px) {
    .menu-list {
      .menu-item {
        flex: 0 0 50%;
      }
    }
  }
  @media only screen and (max-width: 468px) {
    .menu-title {
      font-size: 3rem;
    }
    .menu-list {
      flex-direction: column;
    }
  }
`;

interface List {
  title: string;
  items: ListItem[];
}

interface ListItem {
  title: string;
  description: string[];
}

const Menu = () => {
  const data = useStaticQuery(query);

  return (
    <MenuContainer id="Menu">
      <div>
        <div className="menu-title">
          <StaticImage
            className="icon-shrimp"
            src="../images/icon-shrimp-left.png"
            alt="Shrimp"
            height={70}
            width={70}
          />
          <h2>MENU</h2>
          <StaticImage
            className="icon-shrimp"
            src="../images/icon-shrimp-right.png"
            alt="Shrimp"
            height={70}
            width={70}
          />
        </div>
        <div>
          {data.dataJson.menu.map((list: List, index: number) => (
            <div key={index} className="list-container">
              <div className="list-title">
                <h3>{list.title}</h3>
              </div>
              <div className="menu-list">
                {list.items.map((item: ListItem, index: number) => (
                  <MenuItem
                    key={index}
                    item={item}
                    itemWidth={list.items.length === 4 ? 'one-half' : 'one-third'}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </MenuContainer>
  );
};

export default Menu;

const query = graphql`
  query MenuQuery {
    dataJson {
      menu {
        title
        items {
          title
          description
        }
      }
    }
  }
`;
