import React, { useState, useRef } from 'react';
import useTimeout from '../hooks/useTimeout';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #000;
  padding: 2em 0;
  h3 {
    color: var(--cream);
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  form {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    color: var(--cream);
    text-transform: uppercase;
    input[type='text'],
    input[type='email'] {
      width: 100%;
      padding: 12px 20px;
      margin: 1rem 0;
      box-sizing: border-box;
      font-size: 18px;
    }
    textarea {
      padding: 12px 20px;
      margin: 1rem 0;
      font-size: 18px;
    }
    .actions {
      display: flex;
      gap: 1rem;
      @media only screen and (max-width: 468px) {
        justify-content: center;
        align-items: center;
      }
    }
    .btn {
      border-radius: 5px;
      border: 1px solid var(--orange);
      color: var(--cream);
      background-color: var(--orange);
      font-size: 1.5rem;
      padding: 8px 16px;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        background-color: var(--orange-focus);
        border: 1px solid var(--orange-focus);
      }
    }
  }
  .message {
    color: var(--orange);
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;
    margin: 1rem;
  }
`;

const Msg = styled.div`
  color: var(--orange);
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  margin: 1rem;
`;

interface Props {
  display: boolean;
  setDisplay: (display: boolean) => void;
  error: boolean;
}

const Message: React.FC<Props> = ({ display, setDisplay, error }) => {
  if (!display) return null;

  useTimeout(() => {
    setDisplay(false);
  }, 3000);

  return <Msg>{error ? 'There was a problem please try again later...' : 'Success'}</Msg>;
};

const Contact = () => {
  const [display, setDisplay] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  // form values
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const formRef = useRef(null);

  const encode = (data: any) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const handleSubmit = (event: any) => {
    setDisplay(false);
    setError(false);
    event.preventDefault();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': event?.target?.getAttribute('name'),
        name: name,
        email: email,
        subject: subject,
        message: message,
      }),
    })
      .then(() => {
        setDisplay(true);
        if (formRef.current) {
          (formRef.current as HTMLFormElement).reset();
        }
      })
      .catch((error) => {
        setError(true);
        setDisplay(true);
      });
  };

  const handleNameChange = (evt: any) => {
    setName(evt.target.value);
  };

  const handleEmailChange = (evt: any) => {
    setEmail(evt.target.value);
  };

  const handleSubjectChange = (evt: any) => {
    setSubject(evt.target.value);
  };

  const handleMessageChange = (evt: any) => {
    setMessage(evt.target.value);
  };

  return (
    <Container id="Contact">
      <h3>Contact Us</h3>
      <div className="page-width">
        <form
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="contact"
          action="/"
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            required
            minLength={1}
            value={name}
            onChange={handleNameChange}
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            required
            value={email}
            onChange={handleEmailChange}
          />
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            name="subject"
            id="subject"
            required
            minLength={1}
            value={subject}
            onChange={handleSubjectChange}
          />
          <label>Message</label>
          <textarea
            name="message"
            id="message"
            rows={5}
            required
            minLength={5}
            value={message}
            onChange={handleMessageChange}
          />
          <div className="actions">
            <button className="btn" type="submit">
              Submit
            </button>
          </div>
        </form>
        <Message display={display} setDisplay={setDisplay} error={error} />
      </div>
    </Container>
  );
};

export default Contact;
