import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Menu from '../components/Menu';
import ImageRow from '../components/Image-Row';
import HeroLocation from '../components/Hero-Location';
import Social from '../components/Social';
import Contact from '../components/Contact';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  location: any;
  data: {
    food_image_1: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    food_image_2: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    food_image_3: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    food_image_4: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    food_image_5: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    food_image_6: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
}

// markup
const IndexPage: React.FC<Props> = ({ location, data }) => {
  const imageSet1 = [
    {
      image: data.food_image_1.childImageSharp.gatsbyImageData,
      alt: 'Fish Tacos',
    },
    {
      image: data.food_image_2.childImageSharp.gatsbyImageData,
      alt: 'Shrimp Tacos',
    },
    {
      image: data.food_image_3.childImageSharp.gatsbyImageData,
      alt: 'Shrimp Tostada',
    },
  ];

  const imageSet2 = [
    {
      image: data.food_image_4.childImageSharp.gatsbyImageData,
      alt: 'Carne Asada Fries',
    },
    {
      image: data.food_image_5.childImageSharp.gatsbyImageData,
      alt: 'Octupus taco',
    },
    {
      image: data.food_image_6.childImageSharp.gatsbyImageData,
      alt: 'Ensenada Shrimp Taco',
    },
  ];

  return (
    <Layout location={location}>
      <Seo title="Mariscos | Mexican Fusion" isIndex={true} />
      <Hero overlay={false} />
      <div className="page-width">
        <Menu />
      </div>
      <div className="page-width">
        <ImageRow images={imageSet1} />
      </div>
      <Social />
      <HeroLocation />
      <div className="page-width">
        <ImageRow images={imageSet2} />
      </div>
      <Contact />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query HomePageQuery {
    # hero: file(name: { eq: "hero" }) {
    #   childImageSharp {
    #     gatsbyImageData(placeholder: BLURRED)
    #   }
    # }
    food_image_1: file(name: { eq: "food-1" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1, placeholder: BLURRED)
      }
    }
    food_image_2: file(name: { eq: "food-2" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1, placeholder: BLURRED)
      }
    }
    food_image_3: file(name: { eq: "food-3" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1, placeholder: BLURRED)
      }
    }
    food_image_4: file(name: { eq: "food-4" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1, placeholder: BLURRED)
      }
    }
    food_image_5: file(name: { eq: "food-5" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1, placeholder: BLURRED)
      }
    }
    food_image_6: file(name: { eq: "food-6" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1, placeholder: BLURRED)
      }
    }
  }
`;
