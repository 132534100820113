import React, { useLayoutEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

interface HeroProps {
  headerHeight: number;
}

const HeroContainer = styled.div<HeroProps>`
  font-family: 'Nexa Rust Black', sans-serif;
  display: grid;
  .hero-image {
    grid-area: 1/1;
  }
  .overlay {
    grid-area: 1/1;
    background-color: rgba(0, 0, 0, 0.55);
    z-index: 2;
  }
  @media only screen and (max-width: 468px) {
    .hero-image {
      height: calc(100vh - ${(props) => props.headerHeight}px);
      max-height: calc(100vh - ${(props) => props.headerHeight}px);
    }
  }
`;

interface Props {
  overlay: boolean;
}

const Hero: React.FC<Props> = ({ overlay, children }) => {
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const header = document.querySelector('header') as HTMLElement;
    setHeaderHeight(header.offsetHeight);
  }, []);

  return (
    <HeroContainer className="hero" headerHeight={headerHeight}>
      <StaticImage
        className="hero-image"
        src="../images/hero.jpg"
        alt="The Grill"
        layout="fullWidth"
        formats={['auto', 'webp', 'avif']}
        placeholder="blurred"
      />
      {overlay && <div className="overlay" />}
      <div className="content">{children}</div>
    </HeroContainer>
  );
};

export default Hero;
