import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

const Row = styled.div`
  margin: 3rem auto;
  .row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
  }
  @media only screen and (max-width: 1024px) {
    .row {
      column-gap: 2rem;
    }
  }
  @media only screen and (max-width: 468px) {
    .row {
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }
  }
`;

interface Props {
  images: {
    image: IGatsbyImageData;
    alt: string;
  }[];
}

const ImageRow: React.FC<Props> = ({ images }) => (
  <Row>
    <div className="row">
      {images.map((el: { image: IGatsbyImageData; alt: string }, index: number) => (
        <div key={index} className="image">
          <GatsbyImage image={el.image} alt={el.alt} />
        </div>
      ))}
    </div>
  </Row>
);

export default ImageRow;
