import React from 'react';
import styled from 'styled-components';

const ItemContainer = styled.div`
  box-sizing: border-box;
  padding: 2rem;
  h4 {
    font-size: 2.1rem;
    font-family: 'Humanist 521 Bold', sans-serif;
  }
  p {
    font-size: 1.2rem;
    font-family: 'Humanist 521 Roman', sans-serif;
    text-align: justify;
  }
  @media only screen and (max-width: 468px) {
    padding: 1rem 0;
  }
`;

interface Props {
  item: {
    title: string;
    description: string[];
  };
  itemWidth: string;
}

const MenuItem: React.FC<Props> = ({ item, itemWidth }) => (
  <ItemContainer className={`menu-item ${itemWidth}`}>
    <h4>{item.title}</h4>
    {item.description.map((line: string, index: number) => (
      <p key={index}>{line}</p>
    ))}
  </ItemContainer>
);

export default MenuItem;
