import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const Hero = styled.div`
  font-family: 'Nexa Rust Black', sans-serif;
  display: grid;
  .hero-image {
    grid-area: 1/1;
  }
  .overlay {
    grid-area: 1/1;
    background-color: rgba(0, 0, 0, 0.55);
    z-index: 2;
  }
  .content {
    grid-area: 1/1;
    position: relative;
    place-items: center;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    h3 {
      font-size: 5rem;
      font-weight: bold;
      color: var(--orange);
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    .information {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .schedule {
      border-top: 5px solid var(--orange);
      border-bottom: 5px solid var(--orange);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: bold;
      padding: 0.5rem;
      margin: 1.75rem 0;
      .days {
        color: var(--cream);
        margin-bottom: 0.25rem;
      }
      .hours {
        color: var(--orange);
      }
    }
    .map {
      text-align: center;
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: bold;
      color: var(--cream);
      .map-image {
        margin: 0.5rem;
      }
      .icon-star,
      .icon-bridge {
        margin-right: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .address-title .sub-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
        font-size: 1.3rem;
        .text {
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .content {
      h3 {
        font-size: 3.5rem;
      }
      .schedule,
      .map {
        font-size: 2rem;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .content {
      h3 {
        font-size: 3rem;
      }
      .schedule,
      .map {
        font-size: 1.5rem;
        .address-title .sub-title {
          font-size: 1rem;
          .text {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 468px) {
    .content {
      padding: 2rem 0;
      h3 {
        font-size: 2rem;
      }
      .information {
        grid-template-columns: 1fr;
      }
      .schedule {
        font-size: 2rem;
        row-gap: 1rem;
      }
      .map {
        font-size: 2rem;
        .address-title .sub-title {
          font-size: 0.8rem;
          .text {
            text-align: center;
          }
        }
      }
    }
  }
`;

const HeroLocation = () => {
  const isTemp = false;
  const data = useStaticQuery(query);
  const title = isTemp
    ? data.dataJson.temp_location_hours.title
    : data.dataJson.location_hours.title;
  const hours = isTemp
    ? data.dataJson.temp_location_hours.hours
    : data.dataJson.location_hours.hours;
  const address = isTemp
    ? data.dataJson.temp_location_hours.address
    : data.dataJson.location_hours.address;

  return (
    <Hero id="Location" className="location-hero">
      <StaticImage
        className="hero-image"
        src="../images/location.jpg"
        alt="Location & Hours"
        layout="fullWidth"
        formats={['auto', 'webp', 'avif']}
        placeholder="blurred"
      />
      <div className="overlay" />
      <div className="content">
        <div className="inner-content">
          <h3>{title}</h3>
          <div className="information page-width">
            <div className="schedule">
              {hours.map((el: { days: string; times: string }, index: number) => (
                <div key={index}>
                  <p className="days">{el.days}</p>
                  <p className="hours">{el.times}</p>
                </div>
              ))}
            </div>
            <div className="map">
              <p className="address">
                {address.street}
                <br />
                {`${address.city}, ${address.state} ${address.zip}`}
              </p>
              {address.temporary ? (
                <StaticImage
                  className="map-image"
                  src="../images/downtown-santa-ana-map-temp.svg"
                  alt="Downtown Santa Ana Map"
                  placeholder="blurred"
                />
              ) : (
                <StaticImage
                  className="map-image"
                  src="../images/downtown-santa-ana-map.svg"
                  alt="Downtown Santa Ana Map"
                  placeholder="blurred"
                />
              )}
              <div className="address-title">
                <div>{address.title}</div>
                <div className="sub-title">
                  <div className="icon-star">
                    <StaticImage
                      src="../images/icon-star.svg"
                      alt="Star Icon"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="text">{address.subtitle}</div>
                </div>
                {address.temporary && (
                  <div className="sub-title">
                    <div className="icon-bridge">
                      <StaticImage
                        src="../images/icon-bridge.svg"
                        alt="Star Icon"
                        width={15}
                        height={30}
                      />
                    </div>
                    <div className="text">{address.temporary}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Hero>
  );
};

export default HeroLocation;

const query = graphql`
  query HeroLocationQuery {
    dataJson {
      location_hours {
        title
        hours {
          days
          times
        }
        address {
          title
          subtitle
          street
          city
          state
          zip
        }
      }
      temp_location_hours {
        title
        hours {
          days
          times
        }
        address {
          title
          subtitle
          temporary
          street
          city
          state
          zip
        }
      }
    }
  }
`;
